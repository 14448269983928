<template>
  <v-container fluid class="width-80" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Taxes
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <v-card flat class="custom-grey-border remove-border-radius">
      <v-card-title class="headline grey lighten-4">
        <v-row>
          <v-col md="6" lg="3" class="py-0 my-auto">
            <h3
              class="font-weight-700 custom-headline color-custom-blue"
              style="margin: 3.5px 0px"
            >
              Tax
            </h3>
          </v-col>
          <template v-if="getPermission('setting:update')">
            <v-col md="6" lg="9" class="text-right py-0">
              <v-btn small class="mx-4" v-on:click="createTaxRate">
                <span class="font-size-16 font-weight-600"
                  >Create Tax Rate</span
                >
              </v-btn>
              <v-btn
                small
                class="text-white"
                color="cyan"
                v-on:click="updateTaxesSetting"
                :disabled="pageLoading"
                :loading="pageLoading"
              >
                <span class="font-size-16 font-weight-600">Update</span>
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </v-card-title>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 30vh; position: static"
      >
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row class="custom-border-bottom">
            <v-col md="6">
              <v-text-field
                v-model.trim="setting.tax_name"
                dense
                filled
                label="Tax (eg. GST)"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
              <em class="font-size-12"
                >Tax and number will appear on invoices</em
              >
            </v-col>
            <v-col md="6">
              <v-text-field
                v-model.trim="setting.tax_number"
                dense
                filled
                label="Tax number (eg. GST number)"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12">
              <div class="my-2">
                <h5>
                  Default
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="mx-2" v-bind="attrs" v-on="on"
                        >mdi-progress-question</v-icon
                      >
                    </template>
                    <span
                      >Default tax rate will appear<br />on new invoices and
                      quotations</span
                    >
                  </v-tooltip>
                </h5>
              </div>
              <table class="width-100 mt-4">
                <tbody>
                  <tr v-for="(tax, index) in taxes" :key="index">
                    <td width="50">
                      <v-tooltip left content-class="custom-left-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <v-checkbox
                              v-model="tax.default"
                              v-on:change="updateDefaultTax(index)"
                              class="my-0 py-0"
                              color="cyan"
                              :value="1"
                              hide-details
                            ></v-checkbox>
                          </span>
                        </template>
                        <span>Mark as default</span>
                      </v-tooltip>
                    </td>
                    <td width="400">
                      <v-text-field
                        v-model.trim="tax.tax_name"
                        dense
                        filled
                        label="Tax name"
                        solo
                        flat
                        class="px-4 py-2"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                    <td width="400">
                      <v-text-field
                        v-model.number="tax.tax_rate"
                        dense
                        filled
                        type="number"
                        min="0"
                        max="99.99"
                        step="0.01"
                        label="Tax rate (%)"
                        solo
                        flat
                        class="px-4 py-2"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-select
                        v-model="tax.status"
                        :items="statusList"
                        label="Status"
                        solo
                        flat
                        dense
                        filled
                        hide-details
                        item-color="cyan"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                        class="px-4 py-2"
                      ></v-select>
                    </td>
                    <td
                      width="50"
                      :class="{ 'pointer-events-none': pageLoading }"
                    >
                      <template v-if="taxes.length > 0 && !tax.id">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-on:click="deleteTaxRate(index, tax.id)"
                              color="deep-orange"
                              v-bind="attrs"
                              v-on="on"
                              >mdi-delete</v-icon
                            >
                          </template>
                          <span>Click here to delete</span>
                        </v-tooltip>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </perfect-scrollbar>
    </v-card>
    <v-card
      v-if="false"
      flat
      class="custom-grey-border remove-border-radius mt-4"
    >
      <v-card-title class="headline grey lighten-4">
        <v-row>
          <v-col md="6" lg="4" class="py-0 my-auto">
            <h3
              class="font-weight-700 custom-headline color-custom-blue"
              style="margin: 3.5px 0px"
            >
              Tax Exemption
            </h3>
          </v-col>
          <template v-if="getPermission('setting:update')">
            <v-col md="6" lg="8" class="text-right py-0">
              <v-btn small class="mx-4" v-on:click="createTaxExemption">
                <span class="font-size-16 font-weight-600"
                  >Create Tax Exemption</span
                >
              </v-btn>
              <v-btn
                small
                class="text-white"
                color="cyan"
                v-on:click="updateTaxesSetting"
                :disabled="pageLoading"
                :loading="pageLoading"
              >
                <span class="font-size-16 font-weight-600">Update</span>
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </v-card-title>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 30vh; position: static"
      >
        <v-card-text
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12">
              <div class="my-2">
                <h5>
                  Default
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="mx-2" v-bind="attrs" v-on="on"
                        >mdi-progress-question</v-icon
                      >
                    </template>
                    <span
                      >Default tax exemption will appear<br />on new invoices
                      and quotations</span
                    >
                  </v-tooltip>
                </h5>
              </div>
              <table class="width-100 mt-4">
                <tbody>
                  <tr v-for="(tax, index) in taxExemptions" :key="index">
                    <td width="50">
                      <v-tooltip left content-class="custom-left-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <v-checkbox
                              v-model="tax.default"
                              v-on:change="updateDefaultTaxExemption(index)"
                              class="my-0 py-0"
                              color="cyan"
                              :value="1"
                              hide-details
                            ></v-checkbox>
                          </span>
                        </template>
                        <span>Mark as default</span>
                      </v-tooltip>
                    </td>
                    <td width="400">
                      <v-text-field
                        v-model.trim="tax.tax_name"
                        dense
                        filled
                        label="Tax exemption name"
                        solo
                        flat
                        class="px-4 py-2"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                    <td width="400">
                      <v-text-field
                        v-model.trim="tax.reason"
                        dense
                        filled
                        label="Exemption reason"
                        solo
                        flat
                        class="px-4 py-2"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-select
                        v-model="tax.status"
                        :items="statusList"
                        label="Status"
                        solo
                        flat
                        dense
                        filled
                        hide-details
                        item-color="cyan"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                        class="px-4 py-2"
                      ></v-select>
                    </td>
                    <td
                      width="50"
                      :class="{ 'pointer-events-none': pageLoading }"
                    >
                      <template v-if="taxExemptions.length > 0 && !tax.id">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-on:click="deleteTaxExemption(index, tax.id)"
                              color="deep-orange"
                              v-bind="attrs"
                              v-on="on"
                              >mdi-delete</v-icon
                            >
                          </template>
                          <span>Click here to delete</span>
                        </v-tooltip>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </perfect-scrollbar>
    </v-card>
  </v-container>
</template>

<script>
import { GET, PATCH, DELETE } from "@/core/services/store/request.module";

export default {
  name: "profile-taxes-setting",
  data() {
    return {
      pageLoading: true,
      setting: {
        tax_name: null,
        tax_number: null,
        taxes: [],
        tax_exemptions: [],
      },
      taxes: [
        {
          default: 1,
          tax_name: null,
          tax_rate: null,
          status: 1,
        },
      ],
      taxExemptions: [
        {
          default: 1,
          tax_name: null,
          reason: null,
          status: 1,
        },
      ],
      statusList: [
        {
          value: 1,
          text: "Active",
        },
        {
          value: 0,
          text: "In-Active",
        },
      ],
    };
  },

  watch: {
    taxes: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.length > 0) {
          let result = param.map((row) => {
            return row.default;
          });
          if (!this.lodash.includes(result, 1)) {
            let key = Object.keys(result)[0];
            this.taxes[key].default = 1;
          }
        } else {
          this.createTaxRate();
        }
      },
    },
    taxExemptions: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.length > 0) {
          let result = param.map((row) => {
            return row.default;
          });
          if (!this.lodash.includes(result, 1)) {
            let key = Object.keys(result)[0];
            this.taxExemptions[key].default = 1;
          }
        } else {
          this.createTaxExemption();
        }
      },
    },
  },
  methods: {
    createTaxRate() {
      this.taxes.push({
        default: 0,
        tax_name: null,
        tax_rate: null,
        status: 1,
      });
    },
    deleteTaxRate(index, id) {
      const _this = this;
      if (_this.taxes.length > 1) {
        if (id > 0) {
          _this.pageLoading = true;
          _this.$store
            .dispatch(DELETE, { url: "setting/taxes/" + id })
            .then(() => {
              _this.taxes.splice(index, 1);
            })
            .catch((error) => {
              _this.logError(error);
            })
            .finally(() => {
              _this.pageLoading = false;
              _this.getTaxesSetting();
            });
        } else {
          _this.taxes.splice(index, 1);
        }
      }
    },
    updateDefaultTax(index) {
      for (let i = this.taxes.length - 1; i >= 0; i--) {
        this.taxes[i].default = 0;
      }
      this.taxes[index].default = 1;
    },
    createTaxExemption() {
      this.taxExemptions.push({
        default: 0,
        tax_name: null,
        reason: null,
        status: 1,
      });
    },
    deleteTaxExemption(index, id) {
      const _this = this;
      if (_this.taxExemptions.length > 1) {
        if (id > 0) {
          _this.pageLoading = true;
          _this.$store
            .dispatch(DELETE, { url: "setting/tax_exemptions/" + id })
            .then(() => {
              _this.taxExemptions.splice(index, 1);
            })
            .catch((error) => {
              _this.logError(error);
            })
            .finally(() => {
              _this.pageLoading = false;
              _this.getTaxesSetting();
            });
        } else {
          _this.taxExemptions.splice(index, 1);
        }
      }
    },
    updateDefaultTaxExemption(index) {
      for (let i = this.taxExemptions.length - 1; i >= 0; i--) {
        this.taxExemptions[i].default = 0;
      }
      this.taxExemptions[index].default = 1;
    },
    getTaxesSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/taxes" })
        .then(({ data }) => {
          _this.setting = data;
          _this.taxes = data.taxes.map((row) => {
            return {
              id: row.id,
              default: +row.default,
              tax_name: row.name,
              tax_rate: row.percentage,
              status: +row.activated,
            };
          });

          _this.taxExemptions = data.tax_exemptions.map((row) => {
            return {
              id: row.id,
              default: +row.default,
              tax_name: row.name,
              reason: row.reason,
              status: +row.activated,
            };
          });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateTaxesSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.setting.taxes = _this.taxes;
      _this.setting.tax_exemptions = _this.taxExemptions;
      _this.$store
        .dispatch(PATCH, { url: "setting/taxes", data: _this.setting })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.getTaxesSetting();
        });
    },
  },
  mounted() {
    this.getTaxesSetting();
  },
};
</script>
